<template>
  <div class="closePage">
    <div class="kong_tishi">
      <van-empty image="error" description="该店铺已关闭" />
    </div>
  </div>
</template>
<script>
export default {
  name: "closePage",
  data() {
    return {
      shareid: 0,
      merchid: 0,
    }
  },
  mounted() {
    console.log(this.$route.query.merchid);
    this.merchid = this.$route.query.merchid;
    this.shareid = this.$route.query.shareid;
    // this.$parent.getmerchname(this.merchid, "");
  },
}
</script>
<style lang="less">
.closePage{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 3;
  background-color: #fff;
  .kong_tishi {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -200px;
  }
}
</style>